import * as React from 'react';
import { IRoute } from './routeConfig';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from './RouteWithSubRoutes';

interface RouterProps {
  routes: IRoute[];
}

const Router = ({ routes }: RouterProps) => {
  return (
    <Switch>
      {routes &&
        routes.map((route) => (
          <RouteWithSubRoutes key={route.path} {...route} />
        ))}
    </Switch>
  );
};

export default Router;
