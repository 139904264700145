import { gql } from '@apollo/client';

export const GET_MY_DASHBOARD_INFO = gql`
  query GetMyDashboardInfo {
    getMyDashboardInfo {
      numberOfCases
      numberOfPatients
      numberOfProcedures
      torAssistantPercentage
      torMentorPercentage
      torSurgeonPercentage
      torUnnassignedPercentage
      tosAmbulatoryPercentage
      tosElectivePercentage
      tosEmergencyPercentage
      tosTraumaPercentage
      tosMinorSurgeryPercentage
      tosUnnassignedPercentage
    }
  }
`;
