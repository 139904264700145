import { IStatus, ICollectionMetadata } from 'services/common.models';

export enum TeamRoleEnum {
  surgeon = 'surgeon',
  assistant = 'assistant',
  mentor = 'mentor',
}

export enum FavUnfavSelectionEnum {
  case = 'case',
  patient = 'patient',
  procedure = 'procedure',
}

export enum TypeOfProcedureEnum {
  elective = 'elective',
  ambulatory = 'ambulatory',
  emergency = 'emergency',
  trauma = 'trauma',
}

export enum MalignancyTypeEnum {
  benign = 'benign',
  malignant = 'malignant',
}

export enum ClavienDindoEnum {
  not_available = 'n/a',
  no = 'no',
  grade_i = 'grade_i',
  grade_ii = 'grade_ii',
  grade_iiia = 'grade_iiia',
  grade_iiib = 'grade_iiib',
  grade_iva = 'grade_iva',
  grade_ivb = 'grade_ivb',
  grade_v = 'grade_v',
}

export enum GetPatientSortEnum {
  sort = '',
  a_z = 'a_z',
  z_a = 'z_a',
  recent = 'recent',
  older = 'older',
}

export enum GetPatientFilterEnum {
  all = '',
  favPatients = 'favPatients',
  favCases = 'favCases',
  favProcedures = 'favProcedures',
}

export enum QuickActionPatientDetailEnum {
  delete = 'Delete',
  favourite = 'Favourite',
}

export interface IPatientDetails {
  name: string;
  gender: string;
  dateOfBirth: string;
  caseName?: string;
  email?: string;
  phoneNumber?: string;
}

export interface ITeamDetails {
  institution?: string;
  patientInstitutionId?: string;
  surgeon?: string;
  firstAssistant?: string;
  secondAssistant?: string;
  teamRole?: string | null;
  anesthesiologistName?: string;
  typeOfAnesthesia?: string;
}

export interface IProcedureDetailsAll
  extends IProcedureDetails,
    IDiseaseDetails,
    IComplicationDetails {}

export interface IProcedureDetails {
  diagnosis?: string;
  procedureName?: string;
  dateOfProcedure?: string | null;
  dateOfDischarge?: string | null;
  typeOfProcedure?: string | null;
  summary?: string;
  operationNotes?: string;
}

export interface IDiseaseDetails {
  malignancy?: string | null;
  anatomicGroupId?: string;
  anatomicGroup?: IAnatomicGroup;
  organ?: string;
  histology?: string;
  comorbidities?: string;
  asaClassification?: string;
}

export interface IComplicationDetails {
  clavienDindo?: string | null;
  complication?: string;
  complicationNotes?: string;
  procedureFiles?: string[];
}

export interface IPatientInput
  extends IPatientDetails,
    ITeamDetails,
    IProcedureDetailsAll {
  [key: string]: unknown;
  procedureFiles?: string[];
}

export interface ICreatePatientPayload {
  patientInfo: IPatientInput;
  procedureFiles?: string[];
}

export interface ICreatePatientResponse {
  createPatient: IStatus;
}

export interface IGetPatientResponse {
  getPatient: IPatient;
}

export interface IGetPatientPayload {
  id: number;
}
export interface IPatient {
  createdAt: string;
  dateOfBirth: string;
  gender: string;
  id: string;
  name: string;
  patientId: string;
  updatedAt: string;
  cases: ICase[];
  age: number;
  email: string;
  favorite: boolean;
  phone: string;
}

export interface ICase {
  createdAt: string;
  id: string;
  name: string;
  procedures: IProcedure[];
  procedureEndDate: string;
  procedureRangeDays: number;
  procedureStartDate: string;
  updatedAt: string;
  favorite: boolean;
}

export interface IProcedure extends ITeamDetails, IProcedureDetailsAll {
  id: string;
  procedureId: number;
  case: ICase;
  favorite: boolean;
}

export interface IGetPatientsResponse {
  getPatients: {
    collection: IPatient[];
    metadata: ICollectionMetadata;
  };
}

export interface IGetPatientsPayload {
  page: number;
  limit: number;
  searchTerm?: string;
  searchField?: string;
  sortBy?: string;
}

export interface PatientFormProps {
  saveState?: IPatientInput | undefined;
  isPreview?: boolean;
  onDone?: (
    formData:
      | ITeamDetails
      | IProcedureDetails
      | IDiseaseDetails
      | IComplicationDetails
  ) => void;
}

export interface IPatientDetailAddCase {
  patientId: string;
  caseName?: string;
  dateOfProcedure?: string;
  dateOfDischarge?: string;
}

export interface IAddCaseToPatientPayload extends IPatientDetailAddCase {}

export interface IGetProcedurePayload {
  procedureId: string;
}

export interface IGetProcedureResponse {
  getProcedure: IProcedure;
}

export interface IChangeConfidentialityModePayload {
  confidentialMode: boolean;
}

export interface PatientDetailParams {
  id?: string;
  profile?: string;
  caseId?: string;
  idOfProcedure?: string;
}

interface IProcedureInputType {
  id: string;
  institution?: string;
  patientInstitutionId?: string;
  surgeon?: string;
  firstAssistant?: string;
  secondAssistant?: string;
  teamRole?: TeamRoleEnum;
  anesthesiologistName?: string;
  typeOfAnesthesia?: string;
  diagnosis?: string;
  procedureName?: string;
  dateOfProcedure?: string;
  dateOfDischarge?: string;
  typeOfProcedure?: TypeOfProcedureEnum;
  summary?: string;
  operationNotes?: string;
  malignancy?: MalignancyTypeEnum;
  anatomicGroupId?: string;
  organ?: string;
  histology?: string;
  comorbidities?: string;
  asaClassification?: string;
  clavienDindo?: ClavienDindoEnum;
  complication?: string;
  complicationNotes?: string;
  procedureFiles?: string[];
}

export interface IUpdateProcedurePayload {
  procedureInputType: IProcedureInputType;
}

export interface IUpdateCasePayload {
  caseId: string;
  name: string;
}

export interface IUpdateCaseResponse {
  updateCase: IStatus;
}

export interface IDeletePatientPayload {
  patientId: string;
  password: string;
}

export interface IDeletePatientResponse {
  deletePatient: IStatus;
}

export interface IDeleteCasePayload {
  caseId: string;
  password: string;
}

export interface IDeleteCaseResponse {
  deleteCase: IStatus;
}

export interface IDeleteProcedurePayload {
  procedureId: string;
  password: string;
}

export interface IDeleteProcedureResponse {
  deleteProcedure: IStatus;
}

export interface IGetFavPatientsResponse {
  getFavPatients: {
    collection: IPatient[];
    metadata: ICollectionMetadata;
  };
}

export interface IGetFavPatientsPayload {
  page?: number;
  limit?: number;
  sortBy?: string;
}
export interface IFavUnfavObjectPayload {
  id: string;
  objectType: FavUnfavSelectionEnum;
}

export interface IFavUnfavObjectResponse {
  favUnfavObject: IStatus;
}

export interface IFavCases {
  createdAt: string;
  favorite: boolean;
  id: string;
  name: string;
  procedureEndDate: string;
  procedureRangeDays: number;
  procedureStartDate: string;
  updatedAt: string;
  patient: IPatient;
}

export interface IGetFavCasesResponse {
  getFavCases: {
    collection: IFavCases[];
    metadata: ICollectionMetadata;
  };
}

export interface IGetFavCasesPayload {
  page?: number;
  limit?: number;
  sortBy?: string;
}

export interface IFavProcedures {
  id: string;
  diagnosis: string;
  procedureName: string;
  dateOfProcedure: string;
  patient: IPatient;
}

export interface IGetFavProceduresResponse {
  getFavProcedures: {
    collection: IFavProcedures[];
    metadata: ICollectionMetadata;
  };
}

export interface IGetFavProceduresPayload {
  page?: number;
  limit?: number;
  sortBy?: string;
}

interface IValidateImport {
  col: string;
  remark: string;
  row: string;
}
export interface IValidateImportResponse {
  validateImport: IValidateImport[];
}

export interface IValidateImportPayload {
  signedId: string;
}

export interface IStartImportResponse {
  startImport: IStatus;
}

export interface IStartImportPayload {
  signedId: string;
}

export interface IAnatomicGroup {
  id: string;
  name: string;
}

export interface IGetAnatomicGroupResponse {
  getAnatomicGroup: IAnatomicGroup[];
}
export interface ICreateAnatomicGroupResponse {
  createAnatomicGroup: IAnatomicGroup[];
}

export interface ICreateAnatomicGroupPayload {
  name: string;
}

export interface IPatientInfo extends IPatientDetails {
  id: number;
}

export interface IUpdatePatientProfilePayload {
  patientInfo: IPatientInfo;
}

export interface IUpdatePatientProfileResponse {
  updatePatient: IStatus;
}
