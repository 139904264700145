import { gql } from '@apollo/client';

export const CREATE_PATIENT = gql`
  mutation CreatePatient($patientInfo: PatientInput!) {
    createPatient(patientInfo: $patientInfo) {
      message
      success
    }
  }
`;

export const CHANGE_CONFIDENTIALITY_MODE = gql`
  mutation ChangeConfidentialityMode($confidentialMode: Boolean!) {
    changeConfidentialityMode(confidentialMode: $confidentialMode) {
      message
      success
    }
  }
`;

export const ADD_CASE_TO_PATIENT = gql`
  mutation AddCaseToPatient(
    $patientId: ID!
    $caseName: String
    $dateOfProcedure: ISO8601DateTime
    $dateOfDischarge: ISO8601DateTime
  ) {
    addCaseToPatient(
      patientId: $patientId
      caseName: $caseName
      dateOfProcedure: $dateOfProcedure
      dateOfDischarge: $dateOfDischarge
    ) {
      message
      success
    }
  }
`;

export const UPDATE_PROCEDURE = gql`
  mutation UpdateProcedure($procedureInputType: ProcedureInputTypeForUpdate!) {
    updateProcedure(procedureInputType: $procedureInputType) {
      message
      success
    }
  }
`;

export const UPDATE_CASE = gql`
  mutation UpdateCase($caseId: ID!, $name: String!) {
    updateCase(caseId: $caseId, name: $name) {
      message
      success
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation DeletePatient($patientId: ID!, $password: String!) {
    deletePatient(patientId: $patientId, password: $password) {
      message
      success
    }
  }
`;

export const DELETE_CASE = gql`
  mutation DeleteCase($caseId: ID!, $password: String!) {
    deleteCase(caseId: $caseId, password: $password) {
      message
      success
    }
  }
`;

export const DELETE_PROCEDURE = gql`
  mutation DeleteProcedure($procedureId: ID!, $password: String!) {
    deleteProcedure(procedureId: $procedureId, password: $password) {
      message
      success
    }
  }
`;

export const FAV_UNFAV_OBJECT = gql`
  mutation FavUnfavObject($id: ID!, $objectType: FavUnfavSelection!) {
    favUnfavObject(id: $id, objectType: $objectType) {
      message
      success
    }
  }
`;

export const START_IMPORT = gql`
  mutation StartImport($signedId: String!) {
    startImport(signedId: $signedId) {
      message
      success
    }
  }
`;

export const CREATE_ANATOMIC_GROUP = gql`
  mutation CreateAnatomicGroup($name: String!) {
    createAnatomicGroup(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_PATIENT_DETAIL = gql`
  mutation UpdatePatient($patientInfo: PatientInputTypeForUpdate!) {
    updatePatient(patientInfo: $patientInfo) {
      message
      success
    }
  }
`;
