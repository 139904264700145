import { gql } from '@apollo/client';
import { COLLECTION_METADATA } from '../common.query';
import { GET_PATIENTS_PAYLOAD } from 'services';

export const PROCEDURE_SEARCH_DETAIL = `
  id
  procedureName
  dateOfProcedure
  patient {
    id
    name
    patientId
  }
  case {
    id
    name
  }
`;
export const PATIENTS_SEARCH_DETAIL = `
  id
  name
  age
  gender
  cases {
    id
    name
    procedures {
      id
      procedureName
      dateOfProcedure
    }
  }
`;

export const GET_PROCEDURES_SEARCH = gql`
  query GetProceduresSearch(
    $page: Int!, 
    $limit: Int!, 
    $searchTerm: String!, 
    $searchField: ProcedureSearch,
    $startDateOfProcedure: ISO8601DateTime,
    $endDateOfProcedure: ISO8601DateTime) {
    getProceduresSearch(
      page: $page, 
      limit: $limit, 
      searchTerm: $searchTerm, 
      searchField: $searchField,
      startDateOfProcedure: $startDateOfProcedure,
      endDateOfProcedure: $endDateOfProcedure) {
      collection {
        ${PROCEDURE_SEARCH_DETAIL}
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;

export const GET_PATIENTS_SEARCH = gql`
  query GetPatientsSearch(${GET_PATIENTS_PAYLOAD}) {
    getPatients(page: $page, limit: $limit, searchTerm: $searchTerm, searchField: $searchField, sortBy: $sortBy) {
      collection {
        ${PATIENTS_SEARCH_DETAIL}
      }
      metadata {
        ${COLLECTION_METADATA}
      }
    }
  }
`;
